.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    height: 53px;
    width:  70px;
    border-radius: 5px;
}
.container {
    padding-top: 1em;
}

.rightContainer {
    border-left: 1px solid #20232a;
}

.cardContainer {
    padding-bottom: 1em;
}

.deckContainer, .collectionContainer {
    height: 60vh;
    overflow-y: scroll;
}

.characterTitleWarrior {
    color: RED;
    font-weight: bold;
}
.characterTitleScout {
    color: green;
    font-weight: bold;
}
.characterTitleMage {
    color: darkblue;
    font-weight: bold;
}
.characterTitleHealer {
    color: goldenrod;
    font-weight: bold;
}

.copyUrl, .copyUrl:hover {
    font-size: smaller;
    cursor: pointer;
    color: darkslategrey;
}
@import '../../Styles/common.scss';

$details-font-size: 10px;

.container {
    color: white;
    background-color: $color-dark;
    border-radius: 6px;
    padding-bottom: 2em;
}

.characterType {
    font-size: 16px;
    font-style: italic;
}

.detailsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    color: #c9aa71;
    font-weight: bold;
    margin-right: 1em;
    width: 73px;
}

.detail {
    font-weight: bold;
}

.traitDetail {
    width: 100%;
    background-color: lighten($color-dark, 10%);
    border-radius: 3px;
    margin: 3px 1em 3px 0px;
    text-align: center;
}

.detailsHeader {
    margin-top: 1em;
}

.initialCardsRow {
    display: flex;
    justify-content: space-between;
}

.countContainer {
    margin-top: 3px;
    text-align: center;
}
.countNumber {
    padding: 1px 10px;
    background-color: lighten($color-dark, 10%);
    font-weight: bold;
    border-color: black;
    border-radius: 3px;
}

.weaponCardImageContainer {
    
    height: 125px;
    width: 90px;

    img {
        height: 100%;
        width: 100%
    }
}
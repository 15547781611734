.main {
    padding-bottom: 10vh;
    padding-top: 5vh;
}

.layoutNavBar {
    padding-left: 1em;
    padding-right: 1em;
}

.footer {
    background-color: white;
    width: 100vw;
}